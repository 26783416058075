













import { Component, Emit, Prop, Vue } from 'vue-property-decorator';
import MenuItem from './MenuItem.vue';
import { LinkListItemI } from '@/typings';

@Component({ components: { MenuItem } })
export default class MenuItemList extends Vue {
  @Prop({ type: Array }) menuItems!: LinkListItemI[];
  @Emit('itemClick')
  onItemClick(): void {
    return;
  }
}
