










import { Component, Vue } from 'vue-property-decorator';
import NavBar from '@/components/navigation/NavBar.vue';
import FooterBar from '@/components/footer/FooterBar.vue';

@Component({ components: { NavBar, FooterBar } })
export default class App extends Vue {
  get isFooterHidden(): boolean {
    return this.$route.name === 'Homepage' ? true : false;
  }
}
