




















import { Component, Prop, Vue } from 'vue-property-decorator';
import FacebookIcon from 'vue-material-design-icons/Facebook.vue';
import InstagramIcon from 'vue-material-design-icons/Instagram.vue';
import YoutubeIcon from 'vue-material-design-icons/Youtube.vue';

@Component({ components: { FacebookIcon, InstagramIcon, YoutubeIcon } })
export default class FooterItem extends Vue {
  @Prop({ type: String }) link!: string;
  @Prop({ type: String }) name!: string;

  get ariaLabel(): string {
    return `Oficjalny ${this.name} chóru (link otwiera nowe okno)`;
  }

  get fillColor(): string {
    return '#ffffff';
  }

  get iconType(): string {
    const icons: {
      [key: string]: string;
    } = {
      facebook: 'facebook-icon',
      instagram: 'instagram-icon',
      youtube: 'youtube-icon',
    };

    return icons[this.name];
  }
}
