







import { Component, Vue } from 'vue-property-decorator';
import FooterItemList from './FooterItemList.vue';
import { footerItems } from './footerData';
import { LinkListItemI } from '@/typings';

@Component({ components: { FooterItemList } })
export default class FooterBar extends Vue {
  footerItems: LinkListItemI[] = footerItems;
}
