











import { Component, Prop, Vue } from 'vue-property-decorator';
import FooterItem from './FooterItem.vue';
import { LinkListItemI } from '@/typings';

@Component({ components: { FooterItem } })
export default class FooterItemList extends Vue {
  @Prop({ type: Array }) footerItems!: LinkListItemI[];
}
