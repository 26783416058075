































import { Component, Vue } from 'vue-property-decorator';
import Logo from './Logo.vue';
import MenuItemList from './MenuItemList.vue';
import { menuItems } from './menuData';
import { LinkListItemI } from '@/typings';
import MenuIcon from 'vue-material-design-icons/Menu.vue';

@Component({ components: { Logo, MenuItemList, MenuIcon } })
export default class NavBar extends Vue {
  menuItems: LinkListItemI[] = menuItems;
  isMobileMenuVisible: boolean = false;

  get iconFillColor(): string {
    return '#ffffff';
  }

  get menuIconAriaLabel(): string {
    return this.isMobileMenuVisible
      ? 'Menu główne, kliknięcie zwinie listę menu'
      : 'Menu główne, kliknięcie rozwinie listę menu';
  }

  onDesktopNavItemClick(): void {
    this.isMobileMenuVisible = false;
    window.scrollTo(0, 0);
  }

  toggleMobileMenu(): void {
    this.isMobileMenuVisible = !this.isMobileMenuVisible;
    window.scrollTo(0, 0);
  }
}
