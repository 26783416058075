








import { Component, Emit, Prop, Vue } from 'vue-property-decorator';

@Component({})
export default class MenuItem extends Vue {
  @Prop({ type: String }) link!: string;
  @Emit('itemClick')
  onItemClick(): void {
    return;
  }
}
